import { init } from 'lib/braze';
import * as braze from '@braze/web-sdk';
import { isSSRUserAgent } from './userAgent';
import defaultConfig from '../constants/defaultConfig';

function brazeStore() {
  const initData = {
    isInitialized: false,
  };

  let storeData = {};

  const setData = (data) => {
    storeData = { ...storeData, ...data };
  };
  const getData = (key) => {
    return storeData[key];
  };

  return {
    init(data) {
      setData({ ...initData, ...data });
    },
    setData(newState) {
      setData(newState);
    },
    getData(key) {
      return getData(key);
    },
  };
}

const brazeState = brazeStore();
brazeState.init();

export const getIsBrazeReady = () => brazeState.getData('isInitialized');

export function isBrazeEnabled({ featureConfig, userLoggedIn }) {
  const {
    enabled: isPromotionalPlacementEnabled,
    braze: { enabled: isBrazeEnabled, disableLoggedOutUsers },
  } = featureConfig || {};
  return (
    isPromotionalPlacementEnabled &&
    isBrazeEnabled &&
    navigator.cookieEnabled &&
    !isSSRUserAgent() &&
    (userLoggedIn || (!userLoggedIn && !disableLoggedOutUsers))
  );
}

export function setupBraze({
  featureConfig,
  userId,
  userLoggedIn,
  brazeContentCardsUpdatedHandler,
}) {
  const isBrazeReady = getIsBrazeReady();
  const brazeUserId = getBrazeUserId(userId, userLoggedIn);
  const {
    braze: { sessionTimeoutInSeconds = 180 },
  } = featureConfig;
  if (!isBrazeReady) {
    const { env = 'prod' } = defaultConfig;
    const isInitialized = init({
      featureConfig,
      userId: brazeUserId,
      initOption: {
        enableLogging: env !== 'prod',
        allowUserSuppliedJavascript: true,
        sessionTimeoutInSeconds,
      },
      option: { automaticallyShowInAppMessages: true },
      brazeContentCardsUpdatedHandler,
    });
    // braze.requestPushPermission();
    brazeState.setData({ isInitialized });
  }

  return true;
}

export const destroyBraze = () => {
  const isBrazeReady = getIsBrazeReady();
  if (isBrazeReady) {
    braze.destroy();
    braze.wipeData();
    braze.disableSDK();
    brazeState.setData({ isInitialized: false });
  }
};

export function setBrazeUserProfile(userProfile) {
  const isBrazeReady = getIsBrazeReady();
  if (isBrazeReady) {
    const brazeUser = braze.getUser();
    if (brazeUser && userProfile) {
      const { firstName } = userProfile;

      firstName &&
        typeof brazeUser.setFirstName === 'function' &&
        brazeUser.setFirstName(firstName);
    }
  }
}

function getBrazeUserId(userId, userLoggedIn) {
  const brazeUserId = userLoggedIn ? userId : false;
  return brazeUserId;
}

// const genderMap = new Map([
//   ['female', 'f'],
//   ['male', 'm'],
//   ['not-applicable', 'n'],
//   ['non-binary', 'o'],
// ]);

export function brazeEvent({ eventName, ...payload }) {
  const { eventProps } = payload || {};
  const isBrazeReady = getIsBrazeReady();
  isBrazeReady && eventName && braze.logCustomEvent(eventName, eventProps);
}
